// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import parseXML from "xml-parse-from-string";


import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleUp         as fasFaAngleUp         } from "@fortawesome/pro-solid-svg-icons/faAngleUp";
import { faAngleDown       as fasFaAngleDown       } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight      as fasfaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCalendarAlt     as fasFacalendarAlt     } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faCaretDown       as fasFaCaretDown       } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft       as fasFaCaretLeft       } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight      as fasFaCaretRight      } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faLanguage        as fasFaLanguage        } from "@fortawesome/pro-solid-svg-icons/faLanguage";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faNewspaper       as fasFaNewspaper       } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faPhone           as fasFaPhone           } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faXmark           as fasFaXmark           } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faUsers           as fasFaUsers           } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faComments        as fasFaComments        } from "@fortawesome/pro-solid-svg-icons/faComments";

import { faFacebook        as fabFaFacebook        } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram       as fabFaInstagram       } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faYoutube         as fabFaYoutube         } from "@fortawesome/free-brands-svg-icons/faYoutube";

import   svgTrees                                    from "../../media/fac/fac-trees.svg";
import   svgArtPallet                                from "../../media/fac/fac-art-pallet.svg";
import   svgBook                                     from "../../media/fac/fac-book.svg";
import   svgBookRoots                                from "../../media/fac/fac-book-roots.svg";
import   svgCalendarRegister                         from "../../media/fac/fac-calendar-register.svg";

/**
 * Add solid icons
 */
library.add(fasFaAngleUp, fasFaAngleDown, fasFaAngleLeft, fasfaAngleRight, fasFaBars, fasFacalendarAlt, fasFaNewspaper, fasFaUsers, fasFaComments, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaFolder, fasFaLanguage, fasFaMagnifyingGlass, fasFaPhone, fasFaTag, fasFaXmark);

/**
 * Add brands icons
 */
library.add(fabFaFacebook, fabFaInstagram, fabFaYoutube);

/**
 * Add custom icons
 */
library.add({
    "trees": {
        iconName: "trees",
        prefix: "fac",
        icon: [
            svgTrees.attributes.viewBox.split(" ")[2],
            svgTrees.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(svgTrees.content).firstElementChild.getAttribute("d"),
        ]
    },
    "art-pallet": {
        iconName: "art-pallet",
        prefix: "fac",
        icon: [
            svgArtPallet.attributes.viewBox.split(" ")[2],
            svgArtPallet.attributes.viewBox.split(" ")[3],
            [],
            "e001",
            parseXML(svgArtPallet.content).firstElementChild.getAttribute("d"),
        ]
    },

    "book": {
        iconName: "book",
        prefix: "fac",
        icon: [
            svgBook.attributes.viewBox.split(" ")[2],
            svgBook.attributes.viewBox.split(" ")[3],
            [],
            "e002",
            parseXML(svgBook.content).firstElementChild.getAttribute("d"),
        ]
    },

    "book-roots": {
        iconName: "book-roots",
        prefix: "fac",
        icon: [
            svgBookRoots.attributes.viewBox.split(" ")[2],
            svgBookRoots.attributes.viewBox.split(" ")[3],
            [],
            "e003",
            parseXML(svgBookRoots.content).firstElementChild.getAttribute("d"),
        ]
    },

    "calendar-register": {
        iconName: "calendar-register",
        prefix: "fac",
        icon: [
            svgCalendarRegister.attributes.viewBox.split(" ")[2],
            svgCalendarRegister.attributes.viewBox.split(" ")[3],
            [],
            "e004",
            parseXML(svgCalendarRegister.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
